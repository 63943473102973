/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EModules = (typeof EModules)[keyof typeof EModules];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EModules = {
	HR: 'HR',
	Devices: 'Devices',
	ExitNotices: 'ExitNotices',
} as const;
