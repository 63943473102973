/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EUsersRoles = (typeof EUsersRoles)[keyof typeof EUsersRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EUsersRoles = {
	SuperAdmin: 'SuperAdmin',
	SuperUser: 'SuperUser',
	Admin: 'Admin',
	Responsible: 'Responsible',
	BackOffice: 'BackOffice',
	Operator: 'Operator',
} as const;
