import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from 'store/auth';

import AuthContentLayout from 'components/layouts/unauthorised-layout/partials/auth-content-layout';

import { UnauthorisedLayoutPropsType } from './type-helpers';

const UnauthorisedLayout: FunctionComponent<UnauthorisedLayoutPropsType> = ({ children }) => {
	const { accessToken } = useAuthStore();

	if (accessToken) {
		return <Navigate to="/" replace />;
	}

	return <AuthContentLayout>{children}</AuthContentLayout>;
};

export default UnauthorisedLayout;
