/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
	AbsencesCreateReqDto,
	AbsencesExportParams,
	AbsencesGetListParams,
	AbsencesGetListResDto,
	AbsencesPersonalCreateReqDto,
	AbsencesPersonalGetListParams,
	AbsencesUpdateReqDto,
	AdminCompaniesCreateReqDto,
	AdminCompaniesExportParams,
	AdminCompaniesGetListParams,
	AdminCompaniesGetListResDto,
	AdminCompaniesListItemResDto,
	AdminCompaniesStatusChangeReqDto,
	AdminCreateAdminPanelUsersReqDto,
	AdminSubscriptionsCreateReqDto,
	AdminSubscriptionsListItemResDto,
	AdminUpdateAdminPanelUsersReqDto,
	AuthRefreshReqDto,
	AuthRestorePasswordConfirmReqDto,
	AuthRestorePasswordReqDto,
	AuthSignInReqDto,
	AuthSignInResDto,
	AuthVerifyCodeReqDto,
	CommonServerErrorResDto,
	CommonSuccessResDto,
	DepartmentsCreateReqDto,
	DepartmentsDbDto,
	LeaveRequestsExportParams,
	LeaveRequestsGetListParams,
	LeaveRequestsGetListResDto,
	LeaveRequestsPersonalCreateReqDto,
	LeaveRequestsPersonalGetListParams,
	LeaveRequestsUpdateReqDto,
	NotificationsGetCountersResDto,
	ShiftsCreateReqDto,
	ShiftsDbDto,
	ShiftsUpdateReqDto,
	StampCheckResDto,
	StampPersonalUpdateReqDto,
	StampsUpdatesGetListParams,
	StampsUpdatesGetListResDto,
	StampsUpdatesUpdateReqDto,
	UsersArchiveChangeReqDto,
	UsersCreateReqDto,
	UsersExportParams,
	UsersGetListParams,
	UsersGetListResDto,
	UsersPublicFieldsDto,
	UsersUpdatePersonalInfoReqDto,
	WorkDaysExportParams,
	WorkDaysGetListParams,
	WorkDaysGetListResDto,
	WorkDaysGetStatsParams,
	WorkDaysGetStatsResDto,
	WorkDaysPersonalGetListParams,
	WorkDaysPersonalGetStatsParams,
	WorkDaysStatsExportParams,
	WorkSchedulesCreateReqDto,
	WorkSchedulesGetListParams,
	WorkSchedulesGetListResDto,
	WorkSchedulesUpdateReqDto,
} from '../model';
import type { BodyType, ErrorType } from '../mutator/custom-instance';
import { customInstance } from '../mutator/custom-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Checks if the server is alive and able to respond to requests.
 * @summary Health Check
 */
export const healthCheck = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<CommonSuccessResDto>({ url: `/health`, method: 'GET', signal }, options);
};

export const getHealthCheckQueryKey = () => {
	return [`/health`] as const;
};

export const getHealthCheckQueryOptions = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = ErrorType<unknown>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getHealthCheckQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({ signal }) => healthCheck(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type HealthCheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheck>>>;
export type HealthCheckQueryError = ErrorType<unknown>;

/**
 * @summary Health Check
 */
export const useHealthCheck = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = ErrorType<unknown>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getHealthCheckQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Authenticate a user and return an access token.
 * @summary Sign-in
 */
export const authSignIn = (authSignInReqDto: BodyType<AuthSignInReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<AuthSignInResDto>(
		{ url: `/auth/sign-in`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authSignInReqDto },
		options,
	);
};

export const getAuthSignInMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authSignIn>>, { data: BodyType<AuthSignInReqDto> }> = (props) => {
		const { data } = props ?? {};

		return authSignIn(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthSignInMutationResult = NonNullable<Awaited<ReturnType<typeof authSignIn>>>;
export type AuthSignInMutationBody = BodyType<AuthSignInReqDto>;
export type AuthSignInMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Sign-in
 */
export const useAuthSignIn = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext> => {
	const mutationOptions = getAuthSignInMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Send a verification code to email for password restoration.
 * @summary Restore password
 */
export const authRestorePassword = (
	authRestorePasswordReqDto: BodyType<AuthRestorePasswordReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/auth/restore-password`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authRestorePasswordReqDto },
		options,
	);
};

export const getAuthRestorePasswordMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePassword>>,
		TError,
		{ data: BodyType<AuthRestorePasswordReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authRestorePassword>>,
	TError,
	{ data: BodyType<AuthRestorePasswordReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authRestorePassword>>, { data: BodyType<AuthRestorePasswordReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return authRestorePassword(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthRestorePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authRestorePassword>>>;
export type AuthRestorePasswordMutationBody = BodyType<AuthRestorePasswordReqDto>;
export type AuthRestorePasswordMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Restore password
 */
export const useAuthRestorePassword = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePassword>>,
		TError,
		{ data: BodyType<AuthRestorePasswordReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authRestorePassword>>, TError, { data: BodyType<AuthRestorePasswordReqDto> }, TContext> => {
	const mutationOptions = getAuthRestorePasswordMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Confirm password restoration with the verification code.
 * @summary Restore password confirm
 */
export const authRestorePasswordConfirm = (
	authRestorePasswordConfirmReqDto: BodyType<AuthRestorePasswordConfirmReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/auth/restore-password-confirm`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: authRestorePasswordConfirmReqDto,
		},
		options,
	);
};

export const getAuthRestorePasswordConfirmMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePasswordConfirm>>,
		TError,
		{ data: BodyType<AuthRestorePasswordConfirmReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authRestorePasswordConfirm>>,
	TError,
	{ data: BodyType<AuthRestorePasswordConfirmReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof authRestorePasswordConfirm>>,
		{ data: BodyType<AuthRestorePasswordConfirmReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return authRestorePasswordConfirm(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthRestorePasswordConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof authRestorePasswordConfirm>>>;
export type AuthRestorePasswordConfirmMutationBody = BodyType<AuthRestorePasswordConfirmReqDto>;
export type AuthRestorePasswordConfirmMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Restore password confirm
 */
export const useAuthRestorePasswordConfirm = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePasswordConfirm>>,
		TError,
		{ data: BodyType<AuthRestorePasswordConfirmReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof authRestorePasswordConfirm>>,
	TError,
	{ data: BodyType<AuthRestorePasswordConfirmReqDto> },
	TContext
> => {
	const mutationOptions = getAuthRestorePasswordConfirmMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Verify if the provided code is valid.
 * @summary Check if code is valid
 */
export const authVerifyCode = (authVerifyCodeReqDto: BodyType<AuthVerifyCodeReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/auth/verify-code`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authVerifyCodeReqDto },
		options,
	);
};

export const getAuthVerifyCodeMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authVerifyCode>>, TError, { data: BodyType<AuthVerifyCodeReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authVerifyCode>>, TError, { data: BodyType<AuthVerifyCodeReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authVerifyCode>>, { data: BodyType<AuthVerifyCodeReqDto> }> = (props) => {
		const { data } = props ?? {};

		return authVerifyCode(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthVerifyCodeMutationResult = NonNullable<Awaited<ReturnType<typeof authVerifyCode>>>;
export type AuthVerifyCodeMutationBody = BodyType<AuthVerifyCodeReqDto>;
export type AuthVerifyCodeMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Check if code is valid
 */
export const useAuthVerifyCode = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authVerifyCode>>, TError, { data: BodyType<AuthVerifyCodeReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authVerifyCode>>, TError, { data: BodyType<AuthVerifyCodeReqDto> }, TContext> => {
	const mutationOptions = getAuthVerifyCodeMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Allows refreshing the JWT token using a valid refresh token provided in cookies or in the request body.
 * @summary Refresh JWT Token
 */
export const authRefreshToken = (authRefreshReqDto: BodyType<AuthRefreshReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<AuthSignInResDto>(
		{ url: `/auth/refresh`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authRefreshReqDto },
		options,
	);
};

export const getAuthRefreshTokenMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authRefreshToken>>, TError, { data: BodyType<AuthRefreshReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authRefreshToken>>, TError, { data: BodyType<AuthRefreshReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authRefreshToken>>, { data: BodyType<AuthRefreshReqDto> }> = (props) => {
		const { data } = props ?? {};

		return authRefreshToken(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthRefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof authRefreshToken>>>;
export type AuthRefreshTokenMutationBody = BodyType<AuthRefreshReqDto>;
export type AuthRefreshTokenMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Refresh JWT Token
 */
export const useAuthRefreshToken = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authRefreshToken>>, TError, { data: BodyType<AuthRefreshReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authRefreshToken>>, TError, { data: BodyType<AuthRefreshReqDto> }, TContext> => {
	const mutationOptions = getAuthRefreshTokenMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser, Admin, Responsible, BackOffice, Operator</b>.<br>Retrieve the personal profile information of the current user.
 * @summary Retrieve Personal Information
 */
export const usersGetPersonalInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<AuthSignInResDto>({ url: `/users/personal-profile`, method: 'GET', signal }, options);
};

export const getUsersGetPersonalInfoQueryKey = () => {
	return [`/users/personal-profile`] as const;
};

export const getUsersGetPersonalInfoQueryOptions = <
	TData = Awaited<ReturnType<typeof usersGetPersonalInfo>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetPersonalInfo>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersGetPersonalInfoQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersGetPersonalInfo>>> = ({ signal }) =>
		usersGetPersonalInfo(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof usersGetPersonalInfo>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type UsersGetPersonalInfoQueryResult = NonNullable<Awaited<ReturnType<typeof usersGetPersonalInfo>>>;
export type UsersGetPersonalInfoQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve Personal Information
 */
export const useUsersGetPersonalInfo = <
	TData = Awaited<ReturnType<typeof usersGetPersonalInfo>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetPersonalInfo>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersGetPersonalInfoQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser, Admin, Responsible, BackOffice, Operator</b>.<br>Update the personal profile information of the current user.
 * @summary Update Personal Information
 */
export const usersUpdatePersonalInfo = (
	usersUpdatePersonalInfoReqDto: BodyType<UsersUpdatePersonalInfoReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/users/personal-profile`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: usersUpdatePersonalInfoReqDto,
		},
		options,
	);
};

export const getUsersUpdatePersonalInfoMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersUpdatePersonalInfo>>,
		TError,
		{ data: BodyType<UsersUpdatePersonalInfoReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof usersUpdatePersonalInfo>>,
	TError,
	{ data: BodyType<UsersUpdatePersonalInfoReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof usersUpdatePersonalInfo>>,
		{ data: BodyType<UsersUpdatePersonalInfoReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return usersUpdatePersonalInfo(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UsersUpdatePersonalInfoMutationResult = NonNullable<Awaited<ReturnType<typeof usersUpdatePersonalInfo>>>;
export type UsersUpdatePersonalInfoMutationBody = BodyType<UsersUpdatePersonalInfoReqDto>;
export type UsersUpdatePersonalInfoMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update Personal Information
 */
export const useUsersUpdatePersonalInfo = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersUpdatePersonalInfo>>,
		TError,
		{ data: BodyType<UsersUpdatePersonalInfoReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof usersUpdatePersonalInfo>>,
	TError,
	{ data: BodyType<UsersUpdatePersonalInfoReqDto> },
	TContext
> => {
	const mutationOptions = getUsersUpdatePersonalInfoMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Export the list of company users as a spreadsheet document.
 * @summary Export Users List
 */
export const usersExport = (params?: UsersExportParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<void>({ url: `/users/export`, method: 'GET', params, signal }, options);
};

export const getUsersExportQueryKey = (params?: UsersExportParams) => {
	return [`/users/export`, ...(params ? [params] : [])] as const;
};

export const getUsersExportQueryOptions = <TData = Awaited<ReturnType<typeof usersExport>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: UsersExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersExport>>> = ({ signal }) => usersExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof usersExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type UsersExportQueryResult = NonNullable<Awaited<ReturnType<typeof usersExport>>>;
export type UsersExportQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Export Users List
 */
export const useUsersExport = <TData = Awaited<ReturnType<typeof usersExport>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: UsersExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Retrieve a paginated list of company users.
 * @summary Retrieve Users List
 */
export const usersGetList = (params?: UsersGetListParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<UsersGetListResDto>({ url: `/users`, method: 'GET', params, signal }, options);
};

export const getUsersGetListQueryKey = (params?: UsersGetListParams) => {
	return [`/users`, ...(params ? [params] : [])] as const;
};

export const getUsersGetListQueryOptions = <TData = Awaited<ReturnType<typeof usersGetList>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: UsersGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersGetList>>> = ({ signal }) => usersGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof usersGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type UsersGetListQueryResult = NonNullable<Awaited<ReturnType<typeof usersGetList>>>;
export type UsersGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve Users List
 */
export const useUsersGetList = <TData = Awaited<ReturnType<typeof usersGetList>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: UsersGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin</b>.<br>Create a new company user.
 * @summary Create User
 */
export const usersCreate = (usersCreateReqDto: BodyType<UsersCreateReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/users`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: usersCreateReqDto },
		options,
	);
};

export const getUsersCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: BodyType<UsersCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: BodyType<UsersCreateReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersCreate>>, { data: BodyType<UsersCreateReqDto> }> = (props) => {
		const { data } = props ?? {};

		return usersCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UsersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCreate>>>;
export type UsersCreateMutationBody = BodyType<UsersCreateReqDto>;
export type UsersCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create User
 */
export const useUsersCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: BodyType<UsersCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof usersCreate>>, TError, { data: BodyType<UsersCreateReqDto> }, TContext> => {
	const mutationOptions = getUsersCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Archive or unarchive a company user.
 * @summary Toggle User Archive Status
 */
export const usersArchive = (
	userId: string,
	usersArchiveChangeReqDto: BodyType<UsersArchiveChangeReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/users/${userId}/archive`, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, data: usersArchiveChangeReqDto },
		options,
	);
};

export const getUsersArchiveMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersArchive>>,
		TError,
		{ userId: string; data: BodyType<UsersArchiveChangeReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof usersArchive>>,
	TError,
	{ userId: string; data: BodyType<UsersArchiveChangeReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof usersArchive>>,
		{ userId: string; data: BodyType<UsersArchiveChangeReqDto> }
	> = (props) => {
		const { userId, data } = props ?? {};

		return usersArchive(userId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UsersArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof usersArchive>>>;
export type UsersArchiveMutationBody = BodyType<UsersArchiveChangeReqDto>;
export type UsersArchiveMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Toggle User Archive Status
 */
export const useUsersArchive = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersArchive>>,
		TError,
		{ userId: string; data: BodyType<UsersArchiveChangeReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof usersArchive>>,
	TError,
	{ userId: string; data: BodyType<UsersArchiveChangeReqDto> },
	TContext
> => {
	const mutationOptions = getUsersArchiveMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Update the details of a company user.
 * @summary Update User
 */
export const usersUpdate = (
	userId: string,
	usersCreateReqDto: BodyType<UsersCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/users/${userId}`, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, data: usersCreateReqDto },
		options,
	);
};

export const getUsersUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersUpdate>>,
		TError,
		{ userId: string; data: BodyType<UsersCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof usersUpdate>>,
	TError,
	{ userId: string; data: BodyType<UsersCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersUpdate>>, { userId: string; data: BodyType<UsersCreateReqDto> }> = (
		props,
	) => {
		const { userId, data } = props ?? {};

		return usersUpdate(userId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UsersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersUpdate>>>;
export type UsersUpdateMutationBody = BodyType<UsersCreateReqDto>;
export type UsersUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update User
 */
export const useUsersUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersUpdate>>,
		TError,
		{ userId: string; data: BodyType<UsersCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof usersUpdate>>, TError, { userId: string; data: BodyType<UsersCreateReqDto> }, TContext> => {
	const mutationOptions = getUsersUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Delete a company user.
 * @summary Delete User
 */
export const usersDelete = (userId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/users/${userId}`, method: 'DELETE' }, options);
};

export const getUsersDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersDelete>>, TError, { userId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof usersDelete>>, TError, { userId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersDelete>>, { userId: string }> = (props) => {
		const { userId } = props ?? {};

		return usersDelete(userId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UsersDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof usersDelete>>>;

export type UsersDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete User
 */
export const useUsersDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersDelete>>, TError, { userId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof usersDelete>>, TError, { userId: string }, TContext> => {
	const mutationOptions = getUsersDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Create a new subscription
 * @summary Create a new subscription
 */
export const adminSubscriptionsCreate = (
	adminSubscriptionsCreateReqDto: BodyType<AdminSubscriptionsCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/admin/subscriptions`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: adminSubscriptionsCreateReqDto },
		options,
	);
};

export const getAdminSubscriptionsCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminSubscriptionsCreate>>,
		TError,
		{ data: BodyType<AdminSubscriptionsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminSubscriptionsCreate>>,
	TError,
	{ data: BodyType<AdminSubscriptionsCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof adminSubscriptionsCreate>>,
		{ data: BodyType<AdminSubscriptionsCreateReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return adminSubscriptionsCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminSubscriptionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof adminSubscriptionsCreate>>>;
export type AdminSubscriptionsCreateMutationBody = BodyType<AdminSubscriptionsCreateReqDto>;
export type AdminSubscriptionsCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create a new subscription
 */
export const useAdminSubscriptionsCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminSubscriptionsCreate>>,
		TError,
		{ data: BodyType<AdminSubscriptionsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminSubscriptionsCreate>>,
	TError,
	{ data: BodyType<AdminSubscriptionsCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAdminSubscriptionsCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Retrieve a list of subscriptions
 * @summary Retrieve subscriptions list
 */
export const adminSubscriptionsGetList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<AdminSubscriptionsListItemResDto[]>({ url: `/admin/subscriptions`, method: 'GET', signal }, options);
};

export const getAdminSubscriptionsGetListQueryKey = () => {
	return [`/admin/subscriptions`] as const;
};

export const getAdminSubscriptionsGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof adminSubscriptionsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminSubscriptionsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminSubscriptionsGetListQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminSubscriptionsGetList>>> = ({ signal }) =>
		adminSubscriptionsGetList(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof adminSubscriptionsGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AdminSubscriptionsGetListQueryResult = NonNullable<Awaited<ReturnType<typeof adminSubscriptionsGetList>>>;
export type AdminSubscriptionsGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve subscriptions list
 */
export const useAdminSubscriptionsGetList = <
	TData = Awaited<ReturnType<typeof adminSubscriptionsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminSubscriptionsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminSubscriptionsGetListQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Update a subscription by ID
 * @summary Update a subscription
 */
export const adminSubscriptionsUpdate = (
	subscriptionId: string,
	adminSubscriptionsCreateReqDto: BodyType<AdminSubscriptionsCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/admin/subscriptions/${subscriptionId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: adminSubscriptionsCreateReqDto,
		},
		options,
	);
};

export const getAdminSubscriptionsUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminSubscriptionsUpdate>>,
		TError,
		{ subscriptionId: string; data: BodyType<AdminSubscriptionsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminSubscriptionsUpdate>>,
	TError,
	{ subscriptionId: string; data: BodyType<AdminSubscriptionsCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof adminSubscriptionsUpdate>>,
		{ subscriptionId: string; data: BodyType<AdminSubscriptionsCreateReqDto> }
	> = (props) => {
		const { subscriptionId, data } = props ?? {};

		return adminSubscriptionsUpdate(subscriptionId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminSubscriptionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof adminSubscriptionsUpdate>>>;
export type AdminSubscriptionsUpdateMutationBody = BodyType<AdminSubscriptionsCreateReqDto>;
export type AdminSubscriptionsUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update a subscription
 */
export const useAdminSubscriptionsUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminSubscriptionsUpdate>>,
		TError,
		{ subscriptionId: string; data: BodyType<AdminSubscriptionsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminSubscriptionsUpdate>>,
	TError,
	{ subscriptionId: string; data: BodyType<AdminSubscriptionsCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAdminSubscriptionsUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Delete a subscription by ID. Deletion is only possible if the subscription is not used by any company.
 * @summary Delete a subscription
 */
export const adminSubscriptionsDelete = (subscriptionId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/admin/subscriptions/${subscriptionId}`, method: 'DELETE' }, options);
};

export const getAdminSubscriptionsDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminSubscriptionsDelete>>, TError, { subscriptionId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminSubscriptionsDelete>>, TError, { subscriptionId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminSubscriptionsDelete>>, { subscriptionId: string }> = (props) => {
		const { subscriptionId } = props ?? {};

		return adminSubscriptionsDelete(subscriptionId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminSubscriptionsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof adminSubscriptionsDelete>>>;

export type AdminSubscriptionsDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete a subscription
 */
export const useAdminSubscriptionsDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminSubscriptionsDelete>>, TError, { subscriptionId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof adminSubscriptionsDelete>>, TError, { subscriptionId: string }, TContext> => {
	const mutationOptions = getAdminSubscriptionsDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin</b>.<br>Create a new user for the admin panel
 * @summary Create a new user
 */
export const adminUsersCreate = (
	adminCreateAdminPanelUsersReqDto: BodyType<AdminCreateAdminPanelUsersReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/admin/users`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: adminCreateAdminPanelUsersReqDto },
		options,
	);
};

export const getAdminUsersCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminUsersCreate>>,
		TError,
		{ data: BodyType<AdminCreateAdminPanelUsersReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminUsersCreate>>,
	TError,
	{ data: BodyType<AdminCreateAdminPanelUsersReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminUsersCreate>>, { data: BodyType<AdminCreateAdminPanelUsersReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return adminUsersCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminUsersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof adminUsersCreate>>>;
export type AdminUsersCreateMutationBody = BodyType<AdminCreateAdminPanelUsersReqDto>;
export type AdminUsersCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create a new user
 */
export const useAdminUsersCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminUsersCreate>>,
		TError,
		{ data: BodyType<AdminCreateAdminPanelUsersReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminUsersCreate>>,
	TError,
	{ data: BodyType<AdminCreateAdminPanelUsersReqDto> },
	TContext
> => {
	const mutationOptions = getAdminUsersCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin</b>.<br>Retrieve a list of admin panel users
 * @summary Retrieve users list
 */
export const adminUsersGetList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<UsersPublicFieldsDto[]>({ url: `/admin/users`, method: 'GET', signal }, options);
};

export const getAdminUsersGetListQueryKey = () => {
	return [`/admin/users`] as const;
};

export const getAdminUsersGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof adminUsersGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminUsersGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminUsersGetListQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersGetList>>> = ({ signal }) => adminUsersGetList(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof adminUsersGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AdminUsersGetListQueryResult = NonNullable<Awaited<ReturnType<typeof adminUsersGetList>>>;
export type AdminUsersGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve users list
 */
export const useAdminUsersGetList = <
	TData = Awaited<ReturnType<typeof adminUsersGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminUsersGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminUsersGetListQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin</b>.<br>Update a user by ID
 * @summary Update a user
 */
export const adminUsersUpdate = (
	userId: string,
	adminUpdateAdminPanelUsersReqDto: BodyType<AdminUpdateAdminPanelUsersReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/admin/users/${userId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: adminUpdateAdminPanelUsersReqDto,
		},
		options,
	);
};

export const getAdminUsersUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminUsersUpdate>>,
		TError,
		{ userId: string; data: BodyType<AdminUpdateAdminPanelUsersReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminUsersUpdate>>,
	TError,
	{ userId: string; data: BodyType<AdminUpdateAdminPanelUsersReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof adminUsersUpdate>>,
		{ userId: string; data: BodyType<AdminUpdateAdminPanelUsersReqDto> }
	> = (props) => {
		const { userId, data } = props ?? {};

		return adminUsersUpdate(userId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminUsersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof adminUsersUpdate>>>;
export type AdminUsersUpdateMutationBody = BodyType<AdminUpdateAdminPanelUsersReqDto>;
export type AdminUsersUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update a user
 */
export const useAdminUsersUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminUsersUpdate>>,
		TError,
		{ userId: string; data: BodyType<AdminUpdateAdminPanelUsersReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminUsersUpdate>>,
	TError,
	{ userId: string; data: BodyType<AdminUpdateAdminPanelUsersReqDto> },
	TContext
> => {
	const mutationOptions = getAdminUsersUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin</b>.<br>Delete a user by ID. Deletion is only possible if at least one user with the administrator role remains.
 * @summary Delete a user
 */
export const adminUsersDelete = (userId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/admin/users/${userId}`, method: 'DELETE' }, options);
};

export const getAdminUsersDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminUsersDelete>>, TError, { userId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminUsersDelete>>, TError, { userId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminUsersDelete>>, { userId: string }> = (props) => {
		const { userId } = props ?? {};

		return adminUsersDelete(userId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminUsersDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof adminUsersDelete>>>;

export type AdminUsersDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete a user
 */
export const useAdminUsersDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminUsersDelete>>, TError, { userId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof adminUsersDelete>>, TError, { userId: string }, TContext> => {
	const mutationOptions = getAdminUsersDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Create a new company
 * @summary Create a new company
 */
export const adminCompaniesCreate = (
	adminCompaniesCreateReqDto: BodyType<AdminCompaniesCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/admin/companies`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: adminCompaniesCreateReqDto },
		options,
	);
};

export const getAdminCompaniesCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesCreate>>,
		TError,
		{ data: BodyType<AdminCompaniesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminCompaniesCreate>>,
	TError,
	{ data: BodyType<AdminCompaniesCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminCompaniesCreate>>, { data: BodyType<AdminCompaniesCreateReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return adminCompaniesCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminCompaniesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesCreate>>>;
export type AdminCompaniesCreateMutationBody = BodyType<AdminCompaniesCreateReqDto>;
export type AdminCompaniesCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create a new company
 */
export const useAdminCompaniesCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesCreate>>,
		TError,
		{ data: BodyType<AdminCompaniesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminCompaniesCreate>>,
	TError,
	{ data: BodyType<AdminCompaniesCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAdminCompaniesCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Retrieve a list of companies with pagination
 * @summary Retrieve companies list
 */
export const adminCompaniesGetList = (
	params?: AdminCompaniesGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<AdminCompaniesGetListResDto>({ url: `/admin/companies`, method: 'GET', params, signal }, options);
};

export const getAdminCompaniesGetListQueryKey = (params?: AdminCompaniesGetListParams) => {
	return [`/admin/companies`, ...(params ? [params] : [])] as const;
};

export const getAdminCompaniesGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof adminCompaniesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AdminCompaniesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminCompaniesGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCompaniesGetList>>> = ({ signal }) =>
		adminCompaniesGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AdminCompaniesGetListQueryResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesGetList>>>;
export type AdminCompaniesGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve companies list
 */
export const useAdminCompaniesGetList = <
	TData = Awaited<ReturnType<typeof adminCompaniesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AdminCompaniesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminCompaniesGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Invite a company admin user to the private area
 * @summary Invite company admin user
 */
export const adminCompaniesInvite = (companyId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/admin/companies/${companyId}/invite`, method: 'PATCH' }, options);
};

export const getAdminCompaniesInviteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesInvite>>, TError, { companyId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesInvite>>, TError, { companyId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminCompaniesInvite>>, { companyId: string }> = (props) => {
		const { companyId } = props ?? {};

		return adminCompaniesInvite(companyId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminCompaniesInviteMutationResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesInvite>>>;

export type AdminCompaniesInviteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Invite company admin user
 */
export const useAdminCompaniesInvite = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesInvite>>, TError, { companyId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof adminCompaniesInvite>>, TError, { companyId: string }, TContext> => {
	const mutationOptions = getAdminCompaniesInviteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Change company status (enable/disable)
 * @summary Change company status
 */
export const adminCompaniesChangeStatus = (
	companyId: string,
	adminCompaniesStatusChangeReqDto: BodyType<AdminCompaniesStatusChangeReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/admin/companies/${companyId}/status`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: adminCompaniesStatusChangeReqDto,
		},
		options,
	);
};

export const getAdminCompaniesChangeStatusMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesChangeStatus>>,
		TError,
		{ companyId: string; data: BodyType<AdminCompaniesStatusChangeReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminCompaniesChangeStatus>>,
	TError,
	{ companyId: string; data: BodyType<AdminCompaniesStatusChangeReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof adminCompaniesChangeStatus>>,
		{ companyId: string; data: BodyType<AdminCompaniesStatusChangeReqDto> }
	> = (props) => {
		const { companyId, data } = props ?? {};

		return adminCompaniesChangeStatus(companyId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminCompaniesChangeStatusMutationResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesChangeStatus>>>;
export type AdminCompaniesChangeStatusMutationBody = BodyType<AdminCompaniesStatusChangeReqDto>;
export type AdminCompaniesChangeStatusMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Change company status
 */
export const useAdminCompaniesChangeStatus = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesChangeStatus>>,
		TError,
		{ companyId: string; data: BodyType<AdminCompaniesStatusChangeReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminCompaniesChangeStatus>>,
	TError,
	{ companyId: string; data: BodyType<AdminCompaniesStatusChangeReqDto> },
	TContext
> => {
	const mutationOptions = getAdminCompaniesChangeStatusMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Export the list of companies as a spreadsheet document
 * @summary Export companies list
 */
export const adminCompaniesExport = (
	params?: AdminCompaniesExportParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<void>({ url: `/admin/companies/export`, method: 'GET', params, signal }, options);
};

export const getAdminCompaniesExportQueryKey = (params?: AdminCompaniesExportParams) => {
	return [`/admin/companies/export`, ...(params ? [params] : [])] as const;
};

export const getAdminCompaniesExportQueryOptions = <
	TData = Awaited<ReturnType<typeof adminCompaniesExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AdminCompaniesExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminCompaniesExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCompaniesExport>>> = ({ signal }) =>
		adminCompaniesExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AdminCompaniesExportQueryResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesExport>>>;
export type AdminCompaniesExportQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Export companies list
 */
export const useAdminCompaniesExport = <
	TData = Awaited<ReturnType<typeof adminCompaniesExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AdminCompaniesExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminCompaniesExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Retrieve a list of users for a company
 * @summary Retrieve company users list
 */
export const adminCompaniesUsersGetList = (companyId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<UsersPublicFieldsDto[]>({ url: `/admin/companies/${companyId}/users`, method: 'GET', signal }, options);
};

export const getAdminCompaniesUsersGetListQueryKey = (companyId: string) => {
	return [`/admin/companies/${companyId}/users`] as const;
};

export const getAdminCompaniesUsersGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof adminCompaniesUsersGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	companyId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesUsersGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminCompaniesUsersGetListQueryKey(companyId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCompaniesUsersGetList>>> = ({ signal }) =>
		adminCompaniesUsersGetList(companyId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!companyId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof adminCompaniesUsersGetList>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type AdminCompaniesUsersGetListQueryResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesUsersGetList>>>;
export type AdminCompaniesUsersGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve company users list
 */
export const useAdminCompaniesUsersGetList = <
	TData = Awaited<ReturnType<typeof adminCompaniesUsersGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	companyId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesUsersGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminCompaniesUsersGetListQueryOptions(companyId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Retrieve company information by ID
 * @summary Retrieve company information by ID
 */
export const adminCompaniesGetById = (companyId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<AdminCompaniesListItemResDto>({ url: `/admin/companies/${companyId}`, method: 'GET', signal }, options);
};

export const getAdminCompaniesGetByIdQueryKey = (companyId: string) => {
	return [`/admin/companies/${companyId}`] as const;
};

export const getAdminCompaniesGetByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof adminCompaniesGetById>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	companyId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesGetById>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminCompaniesGetByIdQueryKey(companyId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCompaniesGetById>>> = ({ signal }) =>
		adminCompaniesGetById(companyId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!companyId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof adminCompaniesGetById>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type AdminCompaniesGetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesGetById>>>;
export type AdminCompaniesGetByIdQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Retrieve company information by ID
 */
export const useAdminCompaniesGetById = <
	TData = Awaited<ReturnType<typeof adminCompaniesGetById>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	companyId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adminCompaniesGetById>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAdminCompaniesGetByIdQueryOptions(companyId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Update a company by ID
 * @summary Update a company
 */
export const adminCompaniesUpdate = (
	companyId: string,
	adminCompaniesCreateReqDto: BodyType<AdminCompaniesCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/admin/companies/${companyId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: adminCompaniesCreateReqDto,
		},
		options,
	);
};

export const getAdminCompaniesUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesUpdate>>,
		TError,
		{ companyId: string; data: BodyType<AdminCompaniesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof adminCompaniesUpdate>>,
	TError,
	{ companyId: string; data: BodyType<AdminCompaniesCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof adminCompaniesUpdate>>,
		{ companyId: string; data: BodyType<AdminCompaniesCreateReqDto> }
	> = (props) => {
		const { companyId, data } = props ?? {};

		return adminCompaniesUpdate(companyId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminCompaniesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesUpdate>>>;
export type AdminCompaniesUpdateMutationBody = BodyType<AdminCompaniesCreateReqDto>;
export type AdminCompaniesUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update a company
 */
export const useAdminCompaniesUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof adminCompaniesUpdate>>,
		TError,
		{ companyId: string; data: BodyType<AdminCompaniesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof adminCompaniesUpdate>>,
	TError,
	{ companyId: string; data: BodyType<AdminCompaniesCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAdminCompaniesUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>SuperAdmin, SuperUser</b>.<br>Permanently delete a company by ID and all its associated data
 * @summary Delete a company
 */
export const adminCompaniesDelete = (companyId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/admin/companies/${companyId}`, method: 'DELETE' }, options);
};

export const getAdminCompaniesDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesDelete>>, TError, { companyId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesDelete>>, TError, { companyId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof adminCompaniesDelete>>, { companyId: string }> = (props) => {
		const { companyId } = props ?? {};

		return adminCompaniesDelete(companyId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AdminCompaniesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof adminCompaniesDelete>>>;

export type AdminCompaniesDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete a company
 */
export const useAdminCompaniesDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof adminCompaniesDelete>>, TError, { companyId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof adminCompaniesDelete>>, TError, { companyId: string }, TContext> => {
	const mutationOptions = getAdminCompaniesDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>Creates a new personal leave request for the authenticated user.
 * @summary Create Personal Leave Request
 */
export const leaveRequestsPersonalCreate = (
	leaveRequestsPersonalCreateReqDto: BodyType<LeaveRequestsPersonalCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/leave-requests/personal`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: leaveRequestsPersonalCreateReqDto,
		},
		options,
	);
};

export const getLeaveRequestsPersonalCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>,
		TError,
		{ data: BodyType<LeaveRequestsPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>,
	TError,
	{ data: BodyType<LeaveRequestsPersonalCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>,
		{ data: BodyType<LeaveRequestsPersonalCreateReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return leaveRequestsPersonalCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LeaveRequestsPersonalCreateMutationResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>>;
export type LeaveRequestsPersonalCreateMutationBody = BodyType<LeaveRequestsPersonalCreateReqDto>;
export type LeaveRequestsPersonalCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create Personal Leave Request
 */
export const useLeaveRequestsPersonalCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>,
		TError,
		{ data: BodyType<LeaveRequestsPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof leaveRequestsPersonalCreate>>,
	TError,
	{ data: BodyType<LeaveRequestsPersonalCreateReqDto> },
	TContext
> => {
	const mutationOptions = getLeaveRequestsPersonalCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>Retrieves a list of personal leave requests for the authenticated user.
 * @summary Get Personal Leave Requests
 */
export const leaveRequestsPersonalGetList = (
	params?: LeaveRequestsPersonalGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<LeaveRequestsGetListResDto>({ url: `/leave-requests/personal`, method: 'GET', params, signal }, options);
};

export const getLeaveRequestsPersonalGetListQueryKey = (params?: LeaveRequestsPersonalGetListParams) => {
	return [`/leave-requests/personal`, ...(params ? [params] : [])] as const;
};

export const getLeaveRequestsPersonalGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLeaveRequestsPersonalGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>> = ({ signal }) =>
		leaveRequestsPersonalGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type LeaveRequestsPersonalGetListQueryResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>>;
export type LeaveRequestsPersonalGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get Personal Leave Requests
 */
export const useLeaveRequestsPersonalGetList = <
	TData = Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getLeaveRequestsPersonalGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>Updates an existing personal leave request for the authenticated user.
 * @summary Update Personal Leave Request
 */
export const leaveRequestsPersonalUpdate = (
	leaveRequestId: string,
	leaveRequestsPersonalCreateReqDto: BodyType<LeaveRequestsPersonalCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/leave-requests/personal/${leaveRequestId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: leaveRequestsPersonalCreateReqDto,
		},
		options,
	);
};

export const getLeaveRequestsPersonalUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>,
		TError,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>,
	TError,
	{ leaveRequestId: string; data: BodyType<LeaveRequestsPersonalCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsPersonalCreateReqDto> }
	> = (props) => {
		const { leaveRequestId, data } = props ?? {};

		return leaveRequestsPersonalUpdate(leaveRequestId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LeaveRequestsPersonalUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>>;
export type LeaveRequestsPersonalUpdateMutationBody = BodyType<LeaveRequestsPersonalCreateReqDto>;
export type LeaveRequestsPersonalUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update Personal Leave Request
 */
export const useLeaveRequestsPersonalUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>,
		TError,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof leaveRequestsPersonalUpdate>>,
	TError,
	{ leaveRequestId: string; data: BodyType<LeaveRequestsPersonalCreateReqDto> },
	TContext
> => {
	const mutationOptions = getLeaveRequestsPersonalUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>Deletes a personal leave request for the authenticated user.
 * @summary Delete Personal Leave Request
 */
export const leaveRequestsPersonalDelete = (leaveRequestId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/leave-requests/personal/${leaveRequestId}`, method: 'DELETE' }, options);
};

export const getLeaveRequestsPersonalDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>, TError, { leaveRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>, TError, { leaveRequestId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>, { leaveRequestId: string }> = (props) => {
		const { leaveRequestId } = props ?? {};

		return leaveRequestsPersonalDelete(leaveRequestId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LeaveRequestsPersonalDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>>;

export type LeaveRequestsPersonalDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete Personal Leave Request
 */
export const useLeaveRequestsPersonalDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>, TError, { leaveRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof leaveRequestsPersonalDelete>>, TError, { leaveRequestId: string }, TContext> => {
	const mutationOptions = getLeaveRequestsPersonalDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Export the list of company leave requests as a spreadsheet document.
 * @summary Export leave requests List
 */
export const leaveRequestsExport = (
	params?: LeaveRequestsExportParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<void>({ url: `/leave-requests/export`, method: 'GET', params, signal }, options);
};

export const getLeaveRequestsExportQueryKey = (params?: LeaveRequestsExportParams) => {
	return [`/leave-requests/export`, ...(params ? [params] : [])] as const;
};

export const getLeaveRequestsExportQueryOptions = <
	TData = Awaited<ReturnType<typeof leaveRequestsExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLeaveRequestsExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof leaveRequestsExport>>> = ({ signal }) =>
		leaveRequestsExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type LeaveRequestsExportQueryResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsExport>>>;
export type LeaveRequestsExportQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Export leave requests List
 */
export const useLeaveRequestsExport = <
	TData = Awaited<ReturnType<typeof leaveRequestsExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getLeaveRequestsExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Get company employees leave requests.
 * @summary Get company employees leave requests
 */
export const leaveRequestsGetList = (
	params?: LeaveRequestsGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<LeaveRequestsGetListResDto>({ url: `/leave-requests`, method: 'GET', params, signal }, options);
};

export const getLeaveRequestsGetListQueryKey = (params?: LeaveRequestsGetListParams) => {
	return [`/leave-requests`, ...(params ? [params] : [])] as const;
};

export const getLeaveRequestsGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof leaveRequestsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLeaveRequestsGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof leaveRequestsGetList>>> = ({ signal }) =>
		leaveRequestsGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type LeaveRequestsGetListQueryResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsGetList>>>;
export type LeaveRequestsGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get company employees leave requests
 */
export const useLeaveRequestsGetList = <
	TData = Awaited<ReturnType<typeof leaveRequestsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: LeaveRequestsGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof leaveRequestsGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getLeaveRequestsGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Update a company employee's leave request.
 * @summary Update company employee leave request
 */
export const leaveRequestsUpdate = (
	leaveRequestId: string,
	leaveRequestsUpdateReqDto: BodyType<LeaveRequestsUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/leave-requests/${leaveRequestId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: leaveRequestsUpdateReqDto,
		},
		options,
	);
};

export const getLeaveRequestsUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsUpdate>>,
		TError,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof leaveRequestsUpdate>>,
	TError,
	{ leaveRequestId: string; data: BodyType<LeaveRequestsUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof leaveRequestsUpdate>>,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsUpdateReqDto> }
	> = (props) => {
		const { leaveRequestId, data } = props ?? {};

		return leaveRequestsUpdate(leaveRequestId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LeaveRequestsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsUpdate>>>;
export type LeaveRequestsUpdateMutationBody = BodyType<LeaveRequestsUpdateReqDto>;
export type LeaveRequestsUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update company employee leave request
 */
export const useLeaveRequestsUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof leaveRequestsUpdate>>,
		TError,
		{ leaveRequestId: string; data: BodyType<LeaveRequestsUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof leaveRequestsUpdate>>,
	TError,
	{ leaveRequestId: string; data: BodyType<LeaveRequestsUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getLeaveRequestsUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Delete a company employee's leave request.
 * @summary Delete company employee leave request
 */
export const leaveRequestsDelete = (leaveRequestId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/leave-requests/${leaveRequestId}`, method: 'DELETE' }, options);
};

export const getLeaveRequestsDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsDelete>>, TError, { leaveRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsDelete>>, TError, { leaveRequestId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof leaveRequestsDelete>>, { leaveRequestId: string }> = (props) => {
		const { leaveRequestId } = props ?? {};

		return leaveRequestsDelete(leaveRequestId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LeaveRequestsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof leaveRequestsDelete>>>;

export type LeaveRequestsDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete company employee leave request
 */
export const useLeaveRequestsDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof leaveRequestsDelete>>, TError, { leaveRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof leaveRequestsDelete>>, TError, { leaveRequestId: string }, TContext> => {
	const mutationOptions = getLeaveRequestsDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesPersonalCreate = (
	absencesPersonalCreateReqDto: BodyType<AbsencesPersonalCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/absences/personal`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: absencesPersonalCreateReqDto },
		options,
	);
};

export const getAbsencesPersonalCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesPersonalCreate>>,
		TError,
		{ data: BodyType<AbsencesPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof absencesPersonalCreate>>,
	TError,
	{ data: BodyType<AbsencesPersonalCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof absencesPersonalCreate>>,
		{ data: BodyType<AbsencesPersonalCreateReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return absencesPersonalCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AbsencesPersonalCreateMutationResult = NonNullable<Awaited<ReturnType<typeof absencesPersonalCreate>>>;
export type AbsencesPersonalCreateMutationBody = BodyType<AbsencesPersonalCreateReqDto>;
export type AbsencesPersonalCreateMutationError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesPersonalCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesPersonalCreate>>,
		TError,
		{ data: BodyType<AbsencesPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof absencesPersonalCreate>>,
	TError,
	{ data: BodyType<AbsencesPersonalCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAbsencesPersonalCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesPersonalGetList = (
	params?: AbsencesPersonalGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<AbsencesGetListResDto>({ url: `/absences/personal`, method: 'GET', params, signal }, options);
};

export const getAbsencesPersonalGetListQueryKey = (params?: AbsencesPersonalGetListParams) => {
	return [`/absences/personal`, ...(params ? [params] : [])] as const;
};

export const getAbsencesPersonalGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof absencesPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AbsencesPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAbsencesPersonalGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof absencesPersonalGetList>>> = ({ signal }) =>
		absencesPersonalGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof absencesPersonalGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AbsencesPersonalGetListQueryResult = NonNullable<Awaited<ReturnType<typeof absencesPersonalGetList>>>;
export type AbsencesPersonalGetListQueryError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesPersonalGetList = <
	TData = Awaited<ReturnType<typeof absencesPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AbsencesPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAbsencesPersonalGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesPersonalUpdate = (
	absenceId: string,
	absencesPersonalCreateReqDto: BodyType<AbsencesPersonalCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/absences/personal/${absenceId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: absencesPersonalCreateReqDto,
		},
		options,
	);
};

export const getAbsencesPersonalUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesPersonalUpdate>>,
		TError,
		{ absenceId: string; data: BodyType<AbsencesPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof absencesPersonalUpdate>>,
	TError,
	{ absenceId: string; data: BodyType<AbsencesPersonalCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof absencesPersonalUpdate>>,
		{ absenceId: string; data: BodyType<AbsencesPersonalCreateReqDto> }
	> = (props) => {
		const { absenceId, data } = props ?? {};

		return absencesPersonalUpdate(absenceId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AbsencesPersonalUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof absencesPersonalUpdate>>>;
export type AbsencesPersonalUpdateMutationBody = BodyType<AbsencesPersonalCreateReqDto>;
export type AbsencesPersonalUpdateMutationError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesPersonalUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesPersonalUpdate>>,
		TError,
		{ absenceId: string; data: BodyType<AbsencesPersonalCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof absencesPersonalUpdate>>,
	TError,
	{ absenceId: string; data: BodyType<AbsencesPersonalCreateReqDto> },
	TContext
> => {
	const mutationOptions = getAbsencesPersonalUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>
 */
export const absencesExport = (params?: AbsencesExportParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<void>({ url: `/absences/export`, method: 'GET', params, signal }, options);
};

export const getAbsencesExportQueryKey = (params?: AbsencesExportParams) => {
	return [`/absences/export`, ...(params ? [params] : [])] as const;
};

export const getAbsencesExportQueryOptions = <
	TData = Awaited<ReturnType<typeof absencesExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AbsencesExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAbsencesExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof absencesExport>>> = ({ signal }) => absencesExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof absencesExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AbsencesExportQueryResult = NonNullable<Awaited<ReturnType<typeof absencesExport>>>;
export type AbsencesExportQueryError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesExport = <TData = Awaited<ReturnType<typeof absencesExport>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: AbsencesExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAbsencesExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesGetList = (params?: AbsencesGetListParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<AbsencesGetListResDto>({ url: `/absences`, method: 'GET', params, signal }, options);
};

export const getAbsencesGetListQueryKey = (params?: AbsencesGetListParams) => {
	return [`/absences`, ...(params ? [params] : [])] as const;
};

export const getAbsencesGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof absencesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: AbsencesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAbsencesGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof absencesGetList>>> = ({ signal }) =>
		absencesGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof absencesGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type AbsencesGetListQueryResult = NonNullable<Awaited<ReturnType<typeof absencesGetList>>>;
export type AbsencesGetListQueryError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesGetList = <TData = Awaited<ReturnType<typeof absencesGetList>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: AbsencesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof absencesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getAbsencesGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesCreate = (absencesCreateReqDto: BodyType<AbsencesCreateReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/absences`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: absencesCreateReqDto },
		options,
	);
};

export const getAbsencesCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof absencesCreate>>, TError, { data: BodyType<AbsencesCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof absencesCreate>>, TError, { data: BodyType<AbsencesCreateReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof absencesCreate>>, { data: BodyType<AbsencesCreateReqDto> }> = (props) => {
		const { data } = props ?? {};

		return absencesCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AbsencesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof absencesCreate>>>;
export type AbsencesCreateMutationBody = BodyType<AbsencesCreateReqDto>;
export type AbsencesCreateMutationError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof absencesCreate>>, TError, { data: BodyType<AbsencesCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof absencesCreate>>, TError, { data: BodyType<AbsencesCreateReqDto> }, TContext> => {
	const mutationOptions = getAbsencesCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesUpdate = (
	absenceId: string,
	absencesUpdateReqDto: BodyType<AbsencesUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/absences/${absenceId}`, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, data: absencesUpdateReqDto },
		options,
	);
};

export const getAbsencesUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesUpdate>>,
		TError,
		{ absenceId: string; data: BodyType<AbsencesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof absencesUpdate>>,
	TError,
	{ absenceId: string; data: BodyType<AbsencesUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof absencesUpdate>>,
		{ absenceId: string; data: BodyType<AbsencesUpdateReqDto> }
	> = (props) => {
		const { absenceId, data } = props ?? {};

		return absencesUpdate(absenceId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AbsencesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof absencesUpdate>>>;
export type AbsencesUpdateMutationBody = BodyType<AbsencesUpdateReqDto>;
export type AbsencesUpdateMutationError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof absencesUpdate>>,
		TError,
		{ absenceId: string; data: BodyType<AbsencesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof absencesUpdate>>,
	TError,
	{ absenceId: string; data: BodyType<AbsencesUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getAbsencesUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const absencesDelete = (absenceId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/absences/${absenceId}`, method: 'DELETE' }, options);
};

export const getAbsencesDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof absencesDelete>>, TError, { absenceId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof absencesDelete>>, TError, { absenceId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof absencesDelete>>, { absenceId: string }> = (props) => {
		const { absenceId } = props ?? {};

		return absencesDelete(absenceId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AbsencesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof absencesDelete>>>;

export type AbsencesDeleteMutationError = ErrorType<CommonServerErrorResDto>;

export const useAbsencesDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof absencesDelete>>, TError, { absenceId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof absencesDelete>>, TError, { absenceId: string }, TContext> => {
	const mutationOptions = getAbsencesDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Retrieves the list of all departments available.
 * @summary Get departments list
 */
export const departmentsGetList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<DepartmentsDbDto[]>({ url: `/work-schedules/departments`, method: 'GET', signal }, options);
};

export const getDepartmentsGetListQueryKey = () => {
	return [`/work-schedules/departments`] as const;
};

export const getDepartmentsGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof departmentsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof departmentsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getDepartmentsGetListQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof departmentsGetList>>> = ({ signal }) => departmentsGetList(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof departmentsGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type DepartmentsGetListQueryResult = NonNullable<Awaited<ReturnType<typeof departmentsGetList>>>;
export type DepartmentsGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get departments list
 */
export const useDepartmentsGetList = <
	TData = Awaited<ReturnType<typeof departmentsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof departmentsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getDepartmentsGetListQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Creates a new department in the system.
 * @summary Create department
 */
export const departmentsCreate = (
	departmentsCreateReqDto: BodyType<DepartmentsCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/work-schedules/departments`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: departmentsCreateReqDto },
		options,
	);
};

export const getDepartmentsCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof departmentsCreate>>,
		TError,
		{ data: BodyType<DepartmentsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof departmentsCreate>>, TError, { data: BodyType<DepartmentsCreateReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof departmentsCreate>>, { data: BodyType<DepartmentsCreateReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return departmentsCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DepartmentsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof departmentsCreate>>>;
export type DepartmentsCreateMutationBody = BodyType<DepartmentsCreateReqDto>;
export type DepartmentsCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create department
 */
export const useDepartmentsCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof departmentsCreate>>,
		TError,
		{ data: BodyType<DepartmentsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof departmentsCreate>>, TError, { data: BodyType<DepartmentsCreateReqDto> }, TContext> => {
	const mutationOptions = getDepartmentsCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Updates the details of an existing department.
 * @summary Update department
 */
export const departmentsUpdate = (
	departmentId: string,
	departmentsCreateReqDto: BodyType<DepartmentsCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/work-schedules/departments/${departmentId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: departmentsCreateReqDto,
		},
		options,
	);
};

export const getDepartmentsUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof departmentsUpdate>>,
		TError,
		{ departmentId: string; data: BodyType<DepartmentsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof departmentsUpdate>>,
	TError,
	{ departmentId: string; data: BodyType<DepartmentsCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof departmentsUpdate>>,
		{ departmentId: string; data: BodyType<DepartmentsCreateReqDto> }
	> = (props) => {
		const { departmentId, data } = props ?? {};

		return departmentsUpdate(departmentId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DepartmentsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof departmentsUpdate>>>;
export type DepartmentsUpdateMutationBody = BodyType<DepartmentsCreateReqDto>;
export type DepartmentsUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update department
 */
export const useDepartmentsUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof departmentsUpdate>>,
		TError,
		{ departmentId: string; data: BodyType<DepartmentsCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof departmentsUpdate>>,
	TError,
	{ departmentId: string; data: BodyType<DepartmentsCreateReqDto> },
	TContext
> => {
	const mutationOptions = getDepartmentsUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Deletes a department from the system.
 * @summary Delete department
 */
export const departmentsDelete = (departmentId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/work-schedules/departments/${departmentId}`, method: 'DELETE' }, options);
};

export const getDepartmentsDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof departmentsDelete>>, TError, { departmentId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof departmentsDelete>>, TError, { departmentId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof departmentsDelete>>, { departmentId: string }> = (props) => {
		const { departmentId } = props ?? {};

		return departmentsDelete(departmentId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DepartmentsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof departmentsDelete>>>;

export type DepartmentsDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete department
 */
export const useDepartmentsDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof departmentsDelete>>, TError, { departmentId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof departmentsDelete>>, TError, { departmentId: string }, TContext> => {
	const mutationOptions = getDepartmentsDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Retrieves the list of all shifts available.
 * @summary Get shifts list
 */
export const shiftsGetList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<ShiftsDbDto[]>({ url: `/work-schedules/shifts`, method: 'GET', signal }, options);
};

export const getShiftsGetListQueryKey = () => {
	return [`/work-schedules/shifts`] as const;
};

export const getShiftsGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof shiftsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof shiftsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getShiftsGetListQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof shiftsGetList>>> = ({ signal }) => shiftsGetList(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof shiftsGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type ShiftsGetListQueryResult = NonNullable<Awaited<ReturnType<typeof shiftsGetList>>>;
export type ShiftsGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get shifts list
 */
export const useShiftsGetList = <TData = Awaited<ReturnType<typeof shiftsGetList>>, TError = ErrorType<CommonServerErrorResDto>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof shiftsGetList>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getShiftsGetListQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin</b>.<br>Module: <b>HR</b>.<br>Creates a new shift in the system.
 * @summary Create shift
 */
export const shiftsCreate = (shiftsCreateReqDto: BodyType<ShiftsCreateReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/work-schedules/shifts`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: shiftsCreateReqDto },
		options,
	);
};

export const getShiftsCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof shiftsCreate>>, TError, { data: BodyType<ShiftsCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof shiftsCreate>>, TError, { data: BodyType<ShiftsCreateReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof shiftsCreate>>, { data: BodyType<ShiftsCreateReqDto> }> = (props) => {
		const { data } = props ?? {};

		return shiftsCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ShiftsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof shiftsCreate>>>;
export type ShiftsCreateMutationBody = BodyType<ShiftsCreateReqDto>;
export type ShiftsCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create shift
 */
export const useShiftsCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof shiftsCreate>>, TError, { data: BodyType<ShiftsCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof shiftsCreate>>, TError, { data: BodyType<ShiftsCreateReqDto> }, TContext> => {
	const mutationOptions = getShiftsCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Module: <b>HR</b>.<br>Updates the details of an existing shift.
 * @summary Update shift
 */
export const shiftsUpdate = (
	shiftId: string,
	shiftsUpdateReqDto: BodyType<ShiftsUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/work-schedules/shifts/${shiftId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: shiftsUpdateReqDto,
		},
		options,
	);
};

export const getShiftsUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof shiftsUpdate>>,
		TError,
		{ shiftId: string; data: BodyType<ShiftsUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof shiftsUpdate>>,
	TError,
	{ shiftId: string; data: BodyType<ShiftsUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof shiftsUpdate>>, { shiftId: string; data: BodyType<ShiftsUpdateReqDto> }> = (
		props,
	) => {
		const { shiftId, data } = props ?? {};

		return shiftsUpdate(shiftId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ShiftsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shiftsUpdate>>>;
export type ShiftsUpdateMutationBody = BodyType<ShiftsUpdateReqDto>;
export type ShiftsUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update shift
 */
export const useShiftsUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof shiftsUpdate>>,
		TError,
		{ shiftId: string; data: BodyType<ShiftsUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof shiftsUpdate>>,
	TError,
	{ shiftId: string; data: BodyType<ShiftsUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getShiftsUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin</b>.<br>Module: <b>HR</b>.<br>Deletes a shift from the system.
 * @summary Delete shift
 */
export const shiftsDelete = (shiftId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/work-schedules/shifts/${shiftId}`, method: 'DELETE' }, options);
};

export const getShiftsDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof shiftsDelete>>, TError, { shiftId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof shiftsDelete>>, TError, { shiftId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof shiftsDelete>>, { shiftId: string }> = (props) => {
		const { shiftId } = props ?? {};

		return shiftsDelete(shiftId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ShiftsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof shiftsDelete>>>;

export type ShiftsDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete shift
 */
export const useShiftsDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof shiftsDelete>>, TError, { shiftId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof shiftsDelete>>, TError, { shiftId: string }, TContext> => {
	const mutationOptions = getShiftsDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Retrieves a list of work schedules based on specified filters.
 * @summary Get work schedules
 */
export const workSchedulesGetList = (
	params?: WorkSchedulesGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<WorkSchedulesGetListResDto>({ url: `/work-schedules`, method: 'GET', params, signal }, options);
};

export const getWorkSchedulesGetListQueryKey = (params?: WorkSchedulesGetListParams) => {
	return [`/work-schedules`, ...(params ? [params] : [])] as const;
};

export const getWorkSchedulesGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof workSchedulesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: WorkSchedulesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workSchedulesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkSchedulesGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workSchedulesGetList>>> = ({ signal }) =>
		workSchedulesGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workSchedulesGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkSchedulesGetListQueryResult = NonNullable<Awaited<ReturnType<typeof workSchedulesGetList>>>;
export type WorkSchedulesGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get work schedules
 */
export const useWorkSchedulesGetList = <
	TData = Awaited<ReturnType<typeof workSchedulesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: WorkSchedulesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workSchedulesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkSchedulesGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Creates a new work schedule in the system.
 * @summary Create work schedule
 */
export const workSchedulesCreate = (
	workSchedulesCreateReqDto: BodyType<WorkSchedulesCreateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/work-schedules`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: workSchedulesCreateReqDto },
		options,
	);
};

export const getWorkSchedulesCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof workSchedulesCreate>>,
		TError,
		{ data: BodyType<WorkSchedulesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof workSchedulesCreate>>,
	TError,
	{ data: BodyType<WorkSchedulesCreateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof workSchedulesCreate>>, { data: BodyType<WorkSchedulesCreateReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return workSchedulesCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type WorkSchedulesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof workSchedulesCreate>>>;
export type WorkSchedulesCreateMutationBody = BodyType<WorkSchedulesCreateReqDto>;
export type WorkSchedulesCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create work schedule
 */
export const useWorkSchedulesCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof workSchedulesCreate>>,
		TError,
		{ data: BodyType<WorkSchedulesCreateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof workSchedulesCreate>>, TError, { data: BodyType<WorkSchedulesCreateReqDto> }, TContext> => {
	const mutationOptions = getWorkSchedulesCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Updates the details of an existing work schedule.
 * @summary Update work schedule
 */
export const workSchedulesUpdate = (
	workScheduleId: string,
	workSchedulesUpdateReqDto: BodyType<WorkSchedulesUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/work-schedules/${workScheduleId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: workSchedulesUpdateReqDto,
		},
		options,
	);
};

export const getWorkSchedulesUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof workSchedulesUpdate>>,
		TError,
		{ workScheduleId: string; data: BodyType<WorkSchedulesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof workSchedulesUpdate>>,
	TError,
	{ workScheduleId: string; data: BodyType<WorkSchedulesUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof workSchedulesUpdate>>,
		{ workScheduleId: string; data: BodyType<WorkSchedulesUpdateReqDto> }
	> = (props) => {
		const { workScheduleId, data } = props ?? {};

		return workSchedulesUpdate(workScheduleId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type WorkSchedulesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof workSchedulesUpdate>>>;
export type WorkSchedulesUpdateMutationBody = BodyType<WorkSchedulesUpdateReqDto>;
export type WorkSchedulesUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update work schedule
 */
export const useWorkSchedulesUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof workSchedulesUpdate>>,
		TError,
		{ workScheduleId: string; data: BodyType<WorkSchedulesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof workSchedulesUpdate>>,
	TError,
	{ workScheduleId: string; data: BodyType<WorkSchedulesUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getWorkSchedulesUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>Deletes a work schedule from the system.
 * @summary Delete work schedule
 */
export const workSchedulesDelete = (workScheduleId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/work-schedules/${workScheduleId}`, method: 'DELETE' }, options);
};

export const getWorkSchedulesDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof workSchedulesDelete>>, TError, { workScheduleId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof workSchedulesDelete>>, TError, { workScheduleId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof workSchedulesDelete>>, { workScheduleId: string }> = (props) => {
		const { workScheduleId } = props ?? {};

		return workSchedulesDelete(workScheduleId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type WorkSchedulesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof workSchedulesDelete>>>;

export type WorkSchedulesDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete work schedule
 */
export const useWorkSchedulesDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof workSchedulesDelete>>, TError, { workScheduleId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof workSchedulesDelete>>, TError, { workScheduleId: string }, TContext> => {
	const mutationOptions = getWorkSchedulesDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>This endpoint allows a user to create a personal stamp.
 * @summary Create a personal stamp
 */
export const stampsPersonalCreate = (options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/stamps/personal/create`, method: 'POST' }, options);
};

export const getStampsPersonalCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCreate>>, TError, void, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCreate>>, TError, void, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof stampsPersonalCreate>>, void> = () => {
		return stampsPersonalCreate(requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type StampsPersonalCreateMutationResult = NonNullable<Awaited<ReturnType<typeof stampsPersonalCreate>>>;

export type StampsPersonalCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create a personal stamp
 */
export const useStampsPersonalCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCreate>>, TError, void, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof stampsPersonalCreate>>, TError, void, TContext> => {
	const mutationOptions = getStampsPersonalCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>This endpoint allows a user to check the status of a personal stamp.
 * @summary Check personal stamp
 */
export const stampsPersonalCheck = (options?: SecondParameter<typeof customInstance>) => {
	return customInstance<StampCheckResDto>({ url: `/stamps/personal/check`, method: 'POST' }, options);
};

export const getStampsPersonalCheckMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCheck>>, TError, void, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCheck>>, TError, void, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof stampsPersonalCheck>>, void> = () => {
		return stampsPersonalCheck(requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type StampsPersonalCheckMutationResult = NonNullable<Awaited<ReturnType<typeof stampsPersonalCheck>>>;

export type StampsPersonalCheckMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Check personal stamp
 */
export const useStampsPersonalCheck = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsPersonalCheck>>, TError, void, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof stampsPersonalCheck>>, TError, void, TContext> => {
	const mutationOptions = getStampsPersonalCheckMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>This endpoint allows a user to update an existing personal stamp.
 * @summary Update a personal stamp
 */
export const stampsPersonalUpdate = (
	stampPersonalUpdateReqDto: BodyType<StampPersonalUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/stamps/personal/update`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: stampPersonalUpdateReqDto },
		options,
	);
};

export const getStampsPersonalUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof stampsPersonalUpdate>>,
		TError,
		{ data: BodyType<StampPersonalUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof stampsPersonalUpdate>>,
	TError,
	{ data: BodyType<StampPersonalUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof stampsPersonalUpdate>>, { data: BodyType<StampPersonalUpdateReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return stampsPersonalUpdate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type StampsPersonalUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof stampsPersonalUpdate>>>;
export type StampsPersonalUpdateMutationBody = BodyType<StampPersonalUpdateReqDto>;
export type StampsPersonalUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update a personal stamp
 */
export const useStampsPersonalUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof stampsPersonalUpdate>>,
		TError,
		{ data: BodyType<StampPersonalUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof stampsPersonalUpdate>>,
	TError,
	{ data: BodyType<StampPersonalUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getStampsPersonalUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>This endpoint retrieves a list of stamp updates for authorized users.
 * @summary Get list of stamp updates
 */
export const stampsUpdatesGetList = (
	params?: StampsUpdatesGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<StampsUpdatesGetListResDto>({ url: `/stamps/updates`, method: 'GET', params, signal }, options);
};

export const getStampsUpdatesGetListQueryKey = (params?: StampsUpdatesGetListParams) => {
	return [`/stamps/updates`, ...(params ? [params] : [])] as const;
};

export const getStampsUpdatesGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof stampsUpdatesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: StampsUpdatesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof stampsUpdatesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getStampsUpdatesGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof stampsUpdatesGetList>>> = ({ signal }) =>
		stampsUpdatesGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof stampsUpdatesGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type StampsUpdatesGetListQueryResult = NonNullable<Awaited<ReturnType<typeof stampsUpdatesGetList>>>;
export type StampsUpdatesGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get list of stamp updates
 */
export const useStampsUpdatesGetList = <
	TData = Awaited<ReturnType<typeof stampsUpdatesGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: StampsUpdatesGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof stampsUpdatesGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getStampsUpdatesGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>This endpoint updates a specific stamp update identified by its ID.
 * @summary Update a stamp update
 */
export const stampsUpdateUpdate = (
	stampsUpdateRequestId: string,
	stampsUpdatesUpdateReqDto: BodyType<StampsUpdatesUpdateReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/stamps/updates/${stampsUpdateRequestId}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: stampsUpdatesUpdateReqDto,
		},
		options,
	);
};

export const getStampsUpdateUpdateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof stampsUpdateUpdate>>,
		TError,
		{ stampsUpdateRequestId: string; data: BodyType<StampsUpdatesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof stampsUpdateUpdate>>,
	TError,
	{ stampsUpdateRequestId: string; data: BodyType<StampsUpdatesUpdateReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof stampsUpdateUpdate>>,
		{ stampsUpdateRequestId: string; data: BodyType<StampsUpdatesUpdateReqDto> }
	> = (props) => {
		const { stampsUpdateRequestId, data } = props ?? {};

		return stampsUpdateUpdate(stampsUpdateRequestId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type StampsUpdateUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof stampsUpdateUpdate>>>;
export type StampsUpdateUpdateMutationBody = BodyType<StampsUpdatesUpdateReqDto>;
export type StampsUpdateUpdateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Update a stamp update
 */
export const useStampsUpdateUpdate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof stampsUpdateUpdate>>,
		TError,
		{ stampsUpdateRequestId: string; data: BodyType<StampsUpdatesUpdateReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof stampsUpdateUpdate>>,
	TError,
	{ stampsUpdateRequestId: string; data: BodyType<StampsUpdatesUpdateReqDto> },
	TContext
> => {
	const mutationOptions = getStampsUpdateUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>This endpoint deletes a specific stamp update identified by its ID.
 * @summary Delete a stamp update
 */
export const stampsUpdatesDelete = (stampsUpdateRequestId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/stamps/updates/${stampsUpdateRequestId}`, method: 'DELETE' }, options);
};

export const getStampsUpdatesDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsUpdatesDelete>>, TError, { stampsUpdateRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof stampsUpdatesDelete>>, TError, { stampsUpdateRequestId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof stampsUpdatesDelete>>, { stampsUpdateRequestId: string }> = (props) => {
		const { stampsUpdateRequestId } = props ?? {};

		return stampsUpdatesDelete(stampsUpdateRequestId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type StampsUpdatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof stampsUpdatesDelete>>>;

export type StampsUpdatesDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete a stamp update
 */
export const useStampsUpdatesDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof stampsUpdatesDelete>>, TError, { stampsUpdateRequestId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof stampsUpdatesDelete>>, TError, { stampsUpdateRequestId: string }, TContext> => {
	const mutationOptions = getStampsUpdatesDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>
 */
export const workDaysPersonalGetList = (
	params: WorkDaysPersonalGetListParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<WorkDaysGetListResDto[]>({ url: `/work-days/personal`, method: 'GET', params, signal }, options);
};

export const getWorkDaysPersonalGetListQueryKey = (params: WorkDaysPersonalGetListParams) => {
	return [`/work-days/personal`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysPersonalGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysPersonalGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysPersonalGetList>>> = ({ signal }) =>
		workDaysPersonalGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysPersonalGetListQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysPersonalGetList>>>;
export type WorkDaysPersonalGetListQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysPersonalGetList = <
	TData = Awaited<ReturnType<typeof workDaysPersonalGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysPersonalGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysPersonalGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice, Operator</b>.<br>Module: <b>HR</b>.<br>
 */
export const workDaysPersonalGetStats = (
	params: WorkDaysPersonalGetStatsParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<WorkDaysGetStatsResDto>({ url: `/work-days/personal/stats`, method: 'GET', params, signal }, options);
};

export const getWorkDaysPersonalGetStatsQueryKey = (params: WorkDaysPersonalGetStatsParams) => {
	return [`/work-days/personal/stats`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysPersonalGetStatsQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysPersonalGetStats>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysPersonalGetStatsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetStats>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysPersonalGetStatsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysPersonalGetStats>>> = ({ signal }) =>
		workDaysPersonalGetStats(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetStats>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysPersonalGetStatsQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysPersonalGetStats>>>;
export type WorkDaysPersonalGetStatsQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysPersonalGetStats = <
	TData = Awaited<ReturnType<typeof workDaysPersonalGetStats>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysPersonalGetStatsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysPersonalGetStats>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysPersonalGetStatsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const workDaysGetList = (params: WorkDaysGetListParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<WorkDaysGetListResDto[]>({ url: `/work-days`, method: 'GET', params, signal }, options);
};

export const getWorkDaysGetListQueryKey = (params: WorkDaysGetListParams) => {
	return [`/work-days`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysGetList>>> = ({ signal }) =>
		workDaysGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysGetListQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysGetList>>>;
export type WorkDaysGetListQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysGetList = <TData = Awaited<ReturnType<typeof workDaysGetList>>, TError = ErrorType<CommonServerErrorResDto>>(
	params: WorkDaysGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>
 */
export const workDaysExport = (params: WorkDaysExportParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<void>({ url: `/work-days/export`, method: 'GET', params, signal }, options);
};

export const getWorkDaysExportQueryKey = (params: WorkDaysExportParams) => {
	return [`/work-days/export`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysExportQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysExport>>> = ({ signal }) => workDaysExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysExportQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysExport>>>;
export type WorkDaysExportQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysExport = <TData = Awaited<ReturnType<typeof workDaysExport>>, TError = ErrorType<CommonServerErrorResDto>>(
	params: WorkDaysExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>Module: <b>HR</b>.<br>
 */
export const workDaysGetStats = (
	params: WorkDaysGetStatsParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<WorkDaysGetStatsResDto[]>({ url: `/work-days/stats`, method: 'GET', params, signal }, options);
};

export const getWorkDaysGetStatsQueryKey = (params: WorkDaysGetStatsParams) => {
	return [`/work-days/stats`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysGetStatsQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysGetStats>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysGetStatsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysGetStats>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysGetStatsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysGetStats>>> = ({ signal }) =>
		workDaysGetStats(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysGetStats>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysGetStatsQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysGetStats>>>;
export type WorkDaysGetStatsQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysGetStats = <TData = Awaited<ReturnType<typeof workDaysGetStats>>, TError = ErrorType<CommonServerErrorResDto>>(
	params: WorkDaysGetStatsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysGetStats>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysGetStatsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>
 */
export const workDaysStatsExport = (
	params: WorkDaysStatsExportParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<void>({ url: `/work-days/stats/export`, method: 'GET', params, signal }, options);
};

export const getWorkDaysStatsExportQueryKey = (params: WorkDaysStatsExportParams) => {
	return [`/work-days/stats/export`, ...(params ? [params] : [])] as const;
};

export const getWorkDaysStatsExportQueryOptions = <
	TData = Awaited<ReturnType<typeof workDaysStatsExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysStatsExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysStatsExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getWorkDaysStatsExportQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof workDaysStatsExport>>> = ({ signal }) =>
		workDaysStatsExport(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof workDaysStatsExport>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type WorkDaysStatsExportQueryResult = NonNullable<Awaited<ReturnType<typeof workDaysStatsExport>>>;
export type WorkDaysStatsExportQueryError = ErrorType<CommonServerErrorResDto>;

export const useWorkDaysStatsExport = <
	TData = Awaited<ReturnType<typeof workDaysStatsExport>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: WorkDaysStatsExportParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof workDaysStatsExport>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getWorkDaysStatsExportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Access for: <b>Admin, Responsible, BackOffice</b>.<br>
 */
export const notificationsGetCounters = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<NotificationsGetCountersResDto>({ url: `/notifications/counters`, method: 'GET', signal }, options);
};

export const getNotificationsGetCountersQueryKey = () => {
	return [`/notifications/counters`] as const;
};

export const getNotificationsGetCountersQueryOptions = <
	TData = Awaited<ReturnType<typeof notificationsGetCounters>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationsGetCounters>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getNotificationsGetCountersQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsGetCounters>>> = ({ signal }) =>
		notificationsGetCounters(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof notificationsGetCounters>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type NotificationsGetCountersQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsGetCounters>>>;
export type NotificationsGetCountersQueryError = ErrorType<CommonServerErrorResDto>;

export const useNotificationsGetCounters = <
	TData = Awaited<ReturnType<typeof notificationsGetCounters>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationsGetCounters>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getNotificationsGetCountersQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
