/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type LeaveRequestsUpdateReqDtoStatus = (typeof LeaveRequestsUpdateReqDtoStatus)[keyof typeof LeaveRequestsUpdateReqDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeaveRequestsUpdateReqDtoStatus = {
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
} as const;
