/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type ELeaveRequestType = (typeof ELeaveRequestType)[keyof typeof ELeaveRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ELeaveRequestType = {
	vacation: 'vacation',
	dayOff: 'dayOff',
	rol: 'rol',
} as const;
