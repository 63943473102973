import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { RoleBasedRoutes } from 'routes/consts';
import intl from 'translations/config';
import { logout } from 'utils/logout';

import { SidebarMenuLink } from 'components/layouts/root-layout/partials/sidebar/types';

export const sidebarLinks: Array<SidebarMenuLink> = [
	{
		icon: <PeopleAltOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Users', id: 'YDMrKK' }),
		route: RoleBasedRoutes.users,
	},
	{
		icon: <DevicesOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'GRAid', id: 'dXHy5k' }),
		route: RoleBasedRoutes.graID,
	},
	{
		icon: <CalendarMonthOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Attendance register', id: 'NJFj0k' }),
		route: RoleBasedRoutes.register,
		notifications: true,
	},
	{
		icon: <PeopleAltOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Clients', id: 'iCpuph' }),
		route: RoleBasedRoutes.admin_clients,
	},
	{
		icon: <AttachMoneyOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Subscriptions', id: 'J+dIsA' }),
		route: RoleBasedRoutes.admin_subscriptions,
	},
	{
		icon: <PersonOutlineOutlinedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Users', id: 'YDMrKK' }),
		route: RoleBasedRoutes.admin_users,
	},
];

export const logoutLinks: Array<SidebarMenuLink> = [
	{
		icon: <LogoutRoundedIcon color="primary" />,
		text: intl.formatMessage({ defaultMessage: 'Sign out', id: 'xXbJso' }),
		route: RoleBasedRoutes.root,
		action: () => logout(),
	},
];
