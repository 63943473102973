import { createIntl, createIntlCache } from 'react-intl';
import { enUS, it } from 'date-fns/locale';
import en from 'translations/compiled/en.json';
import ita from 'translations/compiled/ita.json';

export enum LOCALES {
	EN = 'en',
	ITA = 'ita',
}

export const supportedLocales = {
	[LOCALES.EN]: {
		locale: LOCALES.EN,
		messages: en,
	},
	[LOCALES.ITA]: {
		locale: LOCALES.ITA,
		messages: ita,
	},
};

const muiSupportedLocales = {
	[LOCALES.EN]: enUS,
	[LOCALES.ITA]: it,
};

export const defaultLocale = LOCALES.EN;
export const muiDefaultLocale = muiSupportedLocales[defaultLocale];

const cache = createIntlCache();
const intl = createIntl(supportedLocales[defaultLocale], cache);

export default intl;
