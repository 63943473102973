/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type AdminCompaniesExportStatus = (typeof AdminCompaniesExportStatus)[keyof typeof AdminCompaniesExportStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminCompaniesExportStatus = {
	DRAFT: 'DRAFT',
	PENDING: 'PENDING',
	ENABLED: 'ENABLED',
	DISABLED: 'DISABLED',
} as const;
