/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EAbsenceStatus = (typeof EAbsenceStatus)[keyof typeof EAbsenceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EAbsenceStatus = {
	PENDING: 'PENDING',
	ARCHIVED: 'ARCHIVED',
} as const;
