/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EShiftPeriodStatus = (typeof EShiftPeriodStatus)[keyof typeof EShiftPeriodStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EShiftPeriodStatus = {
	PAST: 'PAST',
	CURRENT: 'CURRENT',
	FUTURE: 'FUTURE',
} as const;
