/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EStampingType = (typeof EStampingType)[keyof typeof EStampingType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EStampingType = {
	AUTO: 'AUTO',
	MANUAL: 'MANUAL',
} as const;
