/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EClockingInterval = (typeof EClockingInterval)[keyof typeof EClockingInterval];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EClockingInterval = {
	NUMBER_30: 30,
	NUMBER_60: 60,
	NUMBER_120: 120,
} as const;
