/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EAbsenceType = (typeof EAbsenceType)[keyof typeof EAbsenceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EAbsenceType = {
	illness: 'illness',
	unjustified: 'unjustified',
	other: 'other',
} as const;
