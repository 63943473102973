import { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from 'shared/theme';
import { defaultLocale, muiDefaultLocale, supportedLocales } from 'translations/config';

import Loader from 'components/loader/Loader';

import { RoutesConfig } from '../routes';

import 'shared/styles/fonts.css';
import 'shared/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter(RoutesConfig);
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

const App = () => {
	return (
		<Suspense fallback={<Loader fullpage />}>
			<QueryClientProvider client={queryClient}>
				<IntlProvider {...supportedLocales[defaultLocale]} defaultLocale={defaultLocale}>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={muiDefaultLocale}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<ToastContainer
								position="top-right"
								autoClose={3000}
								theme="dark"
								stacked
								pauseOnHover
								closeOnClick
								hideProgressBar={true}
								transition={Flip}
							/>
							<RouterProvider router={router} />
						</ThemeProvider>
					</LocalizationProvider>
				</IntlProvider>
			</QueryClientProvider>
		</Suspense>
	);
};

export default App;
