import { Routes } from 'routes/consts';

export const logout = (path = Routes.login) => {
	localStorage.clear();
	sessionStorage.clear();
	clearCookies();
	window.location.replace(path);
};

const clearCookies = () => {
	const cookies = document.cookie.split(';');
	cookies.forEach((cookie) => {
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
	});
};
