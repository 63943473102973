import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ColorPalette } from 'shared/theme';

type LoaderPropsType = {
	fullpage?: boolean;
};

const Loader: FC<LoaderPropsType> = ({ fullpage = false }) => {
	return (
		<Box
			sx={{
				width: '100%',
				height: fullpage ? '100vh' : '75%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress sx={{ color: ColorPalette.black }} />
		</Box>
	);
};

export default Loader;
