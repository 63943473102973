import { FC } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';

type InformationTooltipProps = {
	content: React.ReactNode;
	sx?: SxProps;
	children: React.ReactNode;
};

const InformationTooltip: FC<InformationTooltipProps> = ({ content, sx, children }) => {
	return (
		<Tooltip title={content} arrow>
			<Box
				sx={{
					cursor: 'pointer',
					whiteSpace: 'nowrap',
					maxWidth: '300px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					...sx,
				}}
			>
				{children}
			</Box>
		</Tooltip>
	);
};

export default InformationTooltip;
