/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type ShiftPeriodCreateDtoStatus = (typeof ShiftPeriodCreateDtoStatus)[keyof typeof ShiftPeriodCreateDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShiftPeriodCreateDtoStatus = {
	CURRENT: 'CURRENT',
	FUTURE: 'FUTURE',
} as const;
