import { FC, useMemo } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Badge, Divider, Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { ColorPalette } from 'shared/theme';
import { useAuthStore } from 'store/auth';
import { useRegisterStore } from 'store/register';

import InformationTooltip from 'components/inputs/partials/information-tooltip';
import { logoutLinks, sidebarLinks } from 'components/layouts/root-layout/partials/sidebar/config';
import { SidebarMenuLink } from 'components/layouts/root-layout/partials/sidebar/types';
import { DrawerHeader } from 'components/layouts/root-layout/styles';

type SidebarPropsType = {
	open: boolean;
};

const maxNotificationCount = 99;
const Sidebar: FC<SidebarPropsType> = ({ open }) => {
	const { notificationCounters } = useRegisterStore();
	const { user } = useAuthStore();

	const preparedSidebarLinks = useMemo(
		() => sidebarLinks.filter((link) => user?.role && link?.route.access.includes(user.role) && link),
		[user],
	);

	const countersSum: number = useMemo(
		() => (notificationCounters ? Object.values(notificationCounters).reduce((total: number, value: number) => total + value, 0) : 0),
		[notificationCounters],
	);

	const renderListButton = (item: SidebarMenuLink) => {
		return (
			<ListItemButtonStyled
				sx={{
					ml: open ? 3 : 1,
					paddingRight: open ? 2 : 5,
					paddingLeft: 5,
					display: 'flex',
					gap: 3,
				}}
				onClick={() => item.action && item.action()}
			>
				<ListItemIcon sx={{ minWidth: 0 }}>
					{open ? (
						item.icon
					) : (
						<Badge color="error" variant="dot" invisible={open || !item.notifications}>
							<InformationTooltip content={item.text} sx={{ height: '24px', position: 'relative' }}>
								{item.icon}
							</InformationTooltip>
						</Badge>
					)}
				</ListItemIcon>
				<Badge
					color="primary"
					badgeContent={countersSum}
					max={maxNotificationCount}
					invisible={!open || !item.notifications}
					sx={{
						'& .MuiBadge-badge': {
							right: -25,
							top: 13,
						},
					}}
				>
					<ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
				</Badge>
			</ListItemButtonStyled>
		);
	};

	return (
		<Drawer
			variant="permanent"
			open={open}
			sx={{
				'& > .MuiPaper-elevation': {
					backgroundColor: ColorPalette.lightGray,
				},
			}}
		>
			<DrawerHeader />
			<List sx={{ py: 5 }}>
				{preparedSidebarLinks.map((item) => (
					<ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
						<NavLink to={item.route.path}>{renderListButton(item)}</NavLink>
					</ListItem>
				))}
				<Divider sx={{ margin: 5 }} />
				{logoutLinks.map((item) => (
					<ListItem disablePadding key={item.text}>
						{renderListButton(item)}
					</ListItem>
				))}
			</List>
		</Drawer>
	);
};

export default Sidebar;

const NavLink = styled(RouterNavLink)(() => ({
	'display': 'block',
	'textDecoration': 'none',
	'color': `${ColorPalette.black}`,
	'&.active .MuiListItemButton-root': {
		backgroundColor: `${ColorPalette.black}`,
		color: `${ColorPalette.white}`,
	},
	'&.active svg': {
		color: `${ColorPalette.white}`,
	},
	'&.active .MuiBadge-badge': {
		backgroundColor: `${ColorPalette.white}`,
		color: `${ColorPalette.black}`,
	},
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
	borderBottomLeftRadius: theme.spacing(3),
	borderTopLeftRadius: theme.spacing(3),
	transition: '.2s',
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: 280,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	padding: 0,
	borderRadius: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
	padding: 0,
	borderRadius: 0,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(17)} + 1px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));
