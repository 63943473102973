import { useCallback, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuthStore } from 'store/auth';

export const useSocket = () => {
	const { accessToken } = useAuthStore();
	const [isConnected, setIsConnected] = useState(false);
	const socketRef = useRef<Socket | null>(null);

	useEffect(() => {
		const socket = io(process.env.REACT_APP_API_URL, {
			transports: ['websocket', 'polling'],
			auth: {
				token: accessToken,
			},
		});
		socketRef.current = socket;

		socket.on('connect', () => {
			setIsConnected(true);
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const emitEvent = useCallback(
		(event: string, data?: unknown) => {
			if (socketRef.current && isConnected) {
				socketRef.current.emit(event, data);
			}
		},
		[isConnected],
	);

	const onEvent = useCallback((event: string, callback: (data: unknown) => void) => {
		if (socketRef.current) {
			socketRef.current.on(event, callback);
		}
	}, []);

	const offEvent = useCallback((event: string, callback?: (data: unknown) => void) => {
		if (socketRef.current) {
			socketRef.current.off(event, callback);
		}
	}, []);

	return { emitEvent, onEvent, offEvent, isConnected };
};
