import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { QueryKey, UseQueryResult } from '@tanstack/react-query';
import { CommonServerErrorResDto } from 'api/model';
import { ErrorType } from 'api/mutator/custom-instance';

interface UseFetchQueryProps<TData> {
	queryResult: UseQueryResult<TData, ErrorType<CommonServerErrorResDto>> & {
		queryKey: QueryKey;
	};
	onSuccess?: (data: TData) => void;
	onError?: (error: ErrorType<CommonServerErrorResDto>) => void;
}

export const useFetchQuery = <TData,>({ queryResult, onSuccess, onError }: UseFetchQueryProps<TData>) => {
	const { data, isError, error, isSuccess } = queryResult;

	useEffect(() => {
		if (isSuccess && data && onSuccess) {
			onSuccess(data);
		}

		if (isError && error && onError) {
			onError(error);
			toast.error(error.response?.data.message);
		}
	}, [isSuccess, isError, data, error]);

	return queryResult;
};
