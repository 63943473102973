import { AuthSignInResDto } from 'api/model';
import { LS_AUTH_STORE } from 'constants/localstorage-consts';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { Nullable } from './type-helpers';

type AuthStoreType = Nullable<AuthSignInResDto> & {
	emailForRecoveryPassword: string | null;
	verificationCode: string | null;
};

type AuthActions = {
	setAuthData: (auth: AuthSignInResDto) => void;
	setEmailForRecoveryPassword: (isEmailSent: string) => void;
	setVerificationCode: (code: string) => void;
	reset: () => void;
};

const initialState: AuthStoreType = {
	accessToken: null,
	user: null,
	permissions: null,
	emailForRecoveryPassword: null,
	verificationCode: null,
};

const authStore = create<AuthStoreType & AuthActions>()(
	persist(
		(set) => ({
			...initialState,
			setAuthData: (data) => set(() => ({ ...data })),
			setEmailForRecoveryPassword: (emailForRecoveryPassword) => set(() => ({ emailForRecoveryPassword })),
			setVerificationCode: (verificationCode) => set(() => ({ verificationCode })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: LS_AUTH_STORE,
		},
	),
);

export const useAuthStore = () => authStore((state) => state);

export default authStore;
