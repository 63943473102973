import { Box } from '@mui/material';

import { UnauthorisedLayoutPropsType } from 'components/layouts/unauthorised-layout/type-helpers';

import sign_in_background from '../../../../../assets/images/sing-in-background.png';

const AuthContentLayout = ({ children }: UnauthorisedLayoutPropsType) => {
	return (
		<Box
			sx={{
				backgroundImage: `url(${sign_in_background})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{children}
		</Box>
	);
};

export default AuthContentLayout;
