/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EOvertimeInterval = (typeof EOvertimeInterval)[keyof typeof EOvertimeInterval];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EOvertimeInterval = {
	NUMBER_15: 15,
	NUMBER_30: 30,
	NUMBER_60: 60,
} as const;
