import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import authStore from 'store/auth';
import { logout } from 'utils/logout';

export const AXIOS_INSTANCE = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'X-Device-Type': 'web',
	},
	withCredentials: true,
});

interface FailedRequest {
	resolve: (value: AxiosResponse<unknown, unknown> | PromiseLike<AxiosResponse<unknown, unknown>>) => void;
	reject: (reason?: unknown) => void;
}

let isRefreshing = false;
let failedQueue: FailedRequest[] = [];

const processQueue = (
	error: AxiosError | null,
	response?: AxiosResponse<unknown, unknown> | PromiseLike<AxiosResponse<unknown, unknown>>,
) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			response && prom.resolve(response);
		}
	});
	failedQueue = [];
};

AXIOS_INSTANCE.interceptors.request.use(
	function (config) {
		const accessToken = authStore.getState().accessToken;
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

AXIOS_INSTANCE.interceptors.response.use(
	(response: AxiosResponse) => response,
	async (error: AxiosError): Promise<unknown> => {
		const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

		if (error.response?.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				return new Promise<AxiosResponse>((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then(() => AXIOS_INSTANCE(originalRequest))
					.catch((err) => Promise.reject(err));
			}

			originalRequest._retry = true;
			isRefreshing = true;

			try {
				const response = await axios.post<{ accessToken: string; refreshToken?: string }>(
					`${process.env.REACT_APP_API_URL}/auth/refresh`,
					{},
					{ withCredentials: true },
				);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { accessToken, refreshToken, ...rest } = response.data;
				authStore.setState({ accessToken, ...rest });

				processQueue(null, response);

				if (originalRequest.headers) {
					originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
				}

				return AXIOS_INSTANCE(originalRequest);
			} catch (refreshError) {
				processQueue(refreshError as AxiosError);
				logout();
				return Promise.reject(refreshError);
			} finally {
				isRefreshing = false;
			}
		}
		return Promise.reject(error);
	},
);

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = axios.CancelToken.source();
	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
