/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EShiftType = (typeof EShiftType)[keyof typeof EShiftType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EShiftType = {
	Morning: 'Morning',
	Regular: 'Regular',
	Afternoon: 'Afternoon',
} as const;
