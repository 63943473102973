import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { EUsersRoles } from 'api/model';
import { useBasePath } from 'hooks/use-base-path';
import { RoleBasedRoutes, Routes } from 'routes/consts';
import { useAuthStore } from 'store/auth';

interface Props {
	children: ReactElement;
}

export const AuthorisedLayout: FunctionComponent<Props> = ({ children }) => {
	const { accessToken, user } = useAuthStore();
	const basePath = useBasePath();

	const currentRoute = useMemo(() => {
		return Object.values(RoleBasedRoutes).find((route) => {
			return route.path === basePath;
		});
	}, [basePath]);

	if (!accessToken) {
		return <Navigate to={Routes.login} replace />;
	}

	if (user?.role && !currentRoute?.access.includes(user.role)) {
		if (user.role === EUsersRoles.SuperAdmin || user.role === EUsersRoles.SuperUser) {
			return <Navigate to={Routes.admin_clients} replace />;
		}
		if (user.role === EUsersRoles.Admin) {
			return <Navigate to={Routes.users} replace />;
		}
		if (user.role === EUsersRoles.BackOffice || user.role === EUsersRoles.Responsible) {
			return <Navigate to={Routes.register} replace />;
		}
		if (user.role === EUsersRoles.Operator) {
			return <Navigate to={Routes.not_access} replace />;
		}
		return <Navigate to={Routes.root} replace />;
	}
	return <>{children}</>;
};

export default AuthorisedLayout;
